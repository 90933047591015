import { OAuth2TokenResponse } from '@applyboard/school-backend-lib'
import { LocalStorage } from '../LocalStorage'

enum StorageKeys {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export class AuthStorage {
  static setAuthCredentials({ id_token, refresh_token }: OAuth2TokenResponse): void {
    // TODO: Update all the frontend auth code to refer to idToken instead of accessToken
    AuthStorage.setAccessToken(id_token)

    if (refresh_token) {
      AuthStorage.setRefreshToken(refresh_token)
    }
  }

  static removeAuthCredentials(): void {
    LocalStorage.removeItem(StorageKeys.RefreshToken)
    LocalStorage.removeItem(StorageKeys.AccessToken)
  }

  static setAccessToken(newToken: string): void {
    LocalStorage.setItem(StorageKeys.AccessToken, newToken)
  }

  static setRefreshToken(newToken: string): void {
    LocalStorage.setItem(StorageKeys.RefreshToken, newToken)
  }

  static getAccessToken(): string {
    try {
      const item = LocalStorage.getItem(StorageKeys.AccessToken)
      return item
    } catch (err) {
      return ''
    }
  }

  static getRefreshToken(): string {
    try {
      const item = LocalStorage.getItem('refreshToken')
      return item
    } catch (err) {
      return ''
    }
  }
}
