import { useNavigate } from 'react-router-dom'
import { OAuth2TokenResponse } from '@applyboard/school-backend-lib'
import { AuthStorage } from '../../utils'
import { useAuthToken, useLogin, useRefreshToken } from './'
import { useQueryClient } from '@tanstack/react-query'

export function useAuthManager() {
  const navigate = useNavigate()
  const { getAccessTokenPayload } = useAuthToken()
  const queryClient = useQueryClient()
  const { login, isLoggingIn } = useLogin({ setCredentials })
  const { refreshAccessToken, isRefreshingAccessToken, refreshAccessTokenError } = useRefreshToken({
    getRefreshToken: AuthStorage.getRefreshToken,
    setCredentials,
  })

  function isLoggedIn(): Boolean {
    if (
      !!AuthStorage.getAccessToken() &&
      !!AuthStorage.getRefreshToken() &&
      getAccessTokenPayload()
    )
      return true
    return false
  }

  function setCredentials(loginData: OAuth2TokenResponse) {
    AuthStorage.setAuthCredentials(loginData)
  }

  function signout() {
    AuthStorage.removeAuthCredentials()
    queryClient.clear()
    navigate('/login')
  }

  return {
    isLoggedIn,
    isLoggingIn,
    isRefreshingAccessToken,
    login,
    refreshAccessToken,
    refreshAccessTokenError,
    signout,
  }
}
